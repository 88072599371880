/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunnelEngineConfig, FunnelPageData } from '@shared/funnel-engine';
import {
  ClientMotivation,
  CountryIso3166,
  CurrentMedication,
  SignupStatus,
  UnitSystem,
  UserGetQuery,
  WeightLossSolution,
} from '@shared/gql/sdk';

import { IFunnelSession } from '../FunnelContext';
import { ComorbidityQuestions } from '../pages/ComorbidityQuestions';
import { ComorbidityQuestionsForCheckout } from '../pages/ComorbidityQuestionsForCheckout';
import { CurrentGLP1Medication } from '../pages/CurrentGLP1Medication';
import { CurrentMedications } from '../pages/CurrentMedications';
import { EligibilityQuestions } from '../pages/EligibilityQuestions';
import { EligibilityQuestionsForCheckout } from '../pages/EligibilityQuestionsForCheckout';
import { HealthFeelings } from '../pages/HealthFeelings';
import { HeightWeight } from '../pages/HeightWeight';
import { MedicationWanted } from '../pages/MedicationWanted';
import { Motivations } from '../pages/Motivations';
import { MotivationsV2 } from '../pages/MotivationsV2';
import { PastWeightLossSolutions } from '../pages/PastWeightLossSolutions';
import { Pregnancy } from '../pages/Pregnancy';
import { HeardAbout } from '../pages/Program/HeardAbout';
import { Registration } from '../pages/Registration';
import { RegistrationWithoutCoupon } from '../pages/RegistrationWithoutCoupon';
import { SexAssigned } from '../pages/SexAssigned';

type ScreeningResult = UserGetQuery['User']['client']['screeningResults'];

export const hydrateDataFromSession = (config: FunnelEngineConfig, session: IFunnelSession) => {
  const hydratedPages = hydratePagesFromSession(session);
  const initialData: Record<string, Record<string, any>> = {};
  const addData = (section: string, page: string, data: Record<string, any>) => {
    if (!initialData[section]) initialData[section] = {};
    if (!initialData[section][page]) initialData[section][page] = {};

    initialData[section][page] = { ...initialData[section][page], ...data };
  };

  for (const section in config) {
    for (const page in config[section]) {
      const pageConfig = config[section][page];
      if (hydratedPages[pageConfig.component.name]) {
        addData(section, page, hydratedPages[pageConfig.component.name]);
      }
    }
  }

  return initialData;
};

export const hydratePagesFromSession = (session: IFunnelSession) => {
  const { user } = session;
  const { client } = user || {};
  const isValid = client && client.signupStatus !== SignupStatus.Expired;
  return {
    [Registration.name]: getRegistrationData(user, session.context.prefilledDiscountCode),
    [RegistrationWithoutCoupon.name]: getRegistrationData(user),
    [HeardAbout.name]: { heardAboutEmbla: user?.client?.heardAboutEmbla },
    [HeightWeight.name]: user?.heightCm && user?.weightKg && getHeightWeightData(user),
    [SexAssigned.name]: client?.sexAssignedAtBirth && getSexAssignedData(user),
    [Pregnancy.name]: isValid && client?.signupPregnantOrBreastfeeding !== null && getPregnancyData(user),
    [EligibilityQuestions.name]:
      isValid && screeningResultValid(client?.screeningResults) && getEligibilityData(client.screeningResults),
    [EligibilityQuestionsForCheckout.name]:
      isValid &&
      screeningResultValid(client?.screeningResults) &&
      getEligibilityForCheckoutData(client.screeningResults),
    [ComorbidityQuestions.name]:
      isValid && screeningResultValid(client?.screeningResults) && getComorbidityData(client.screeningResults),
    [ComorbidityQuestionsForCheckout.name]:
      isValid && screeningResultValid(client?.screeningResults) && getComorbidityData(client.screeningResults),
    [CurrentMedications.name]: isValid && !!client?.signupCurrentMedication?.length && getCurrentMedicationData(user),
    [PastWeightLossSolutions.name]:
      isValid &&
      !!client?.pastWeightLossSolutions?.length &&
      getPastWeightLossSolutionsData(client.pastWeightLossSolutions),
    [Motivations.name]: isValid && !!client?.motivations?.length && getMotivationsData(client.motivations),
    [MotivationsV2.name]: isValid && !!client?.motivations?.length && getMotivationsData(client.motivations),
    [HealthFeelings.name]: isValid &&
      client?.feelingAboutWeightLoss && {
        feelingAboutWeightLoss: client?.feelingAboutWeightLoss,
      },
    [CurrentGLP1Medication.name]: isValid &&
      client?.signedupWhileOnMedication !== null && {
        value: client?.signedupWhileOnMedication,
      },
    [MedicationWanted.name]: isValid && client?.signupMedicationWanted !== null && getMedicationWanted(user),
    [HeardAbout.name]: isValid && client?.heardAboutEmbla && { value: client?.heardAboutEmbla },
  };
};

export const getSexAssignedData = (user: UserGetQuery['User']): FunnelPageData<typeof SexAssigned> => ({
  value: user?.client?.sexAssignedAtBirth,
});

export const getPregnancyData = (user: UserGetQuery['User']): FunnelPageData<typeof Pregnancy> => ({
  value: user?.client?.signupPregnantOrBreastfeeding,
});

export const getCurrentMedicationData = (user: UserGetQuery['User']): FunnelPageData<typeof CurrentMedications> => ({
  INSULIN: user?.client?.signupCurrentMedication?.includes(CurrentMedication.Insulin),
  ORLISTAT: user?.client?.signupCurrentMedication?.includes(CurrentMedication.Orlistat),
  SULFONYLUREAS: user?.client?.signupCurrentMedication?.includes(CurrentMedication.Sulfonylureas),
  NONE: user?.client?.signupCurrentMedication?.includes(CurrentMedication.None),
});

export const getMedicationWanted = (user: UserGetQuery['User']): FunnelPageData<typeof MedicationWanted> => ({
  value: user?.client?.signupMedicationWanted,
});

export const getRegistrationData = (
  user: UserGetQuery['User'],
  discountCode?: string,
): FunnelPageData<typeof RegistrationWithoutCoupon | typeof Registration> => {
  return {
    email: user?.email,
    phone: user?.phoneNumber,
    firstName: user?.firstName,
    lastName: user?.lastName,
    coupon: discountCode,
    confirmAge: !!user,
    termsOfService: !!user,
    emailMarketing: user?.client.emailMarketing || false,
  };
};

export const getHeightWeightData = (user: UserGetQuery['User']): FunnelPageData<typeof HeightWeight> => {
  const { heightCm, weightKg } = user;
  const weight = weightKg ? parseFloat(weightKg) : undefined;
  const countryDefault = user.countryISO3166 === CountryIso3166.Gb ? UnitSystem.Imperial : UnitSystem.Metric;

  return {
    height: heightCm,
    weight,
    unitSystem: user?.unitSystem || countryDefault,
  };
};
const screeningResultValid = (screeningResult: ScreeningResult) => {
  if (!screeningResult) return false;
  try {
    return JSON.parse(screeningResult).length > 1;
  } catch (e) {
    return false;
  }
};

const getScreeningResultValue = (screeningResult: ScreeningResult, key: string) => {
  try {
    const parsed = JSON.parse(screeningResult) as {
      id: string;
      value: boolean;
    }[];
    const entry = parsed.find((i) => i.id === key);

    if (entry) return !entry.value;
  } catch (e) {
    return false;
  }
};

const removeUndefinedFields = (obj: Record<string, any>) =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== undefined));

export const getEligibilityData = (screeningResult: ScreeningResult): FunnelPageData<typeof EligibilityQuestions> => {
  return removeUndefinedFields({
    cancer: getScreeningResultValue(screeningResult, 'cancer'),
    pregnancy: getScreeningResultValue(screeningResult, 'pregnancy'),
    alcohol_drugs: getScreeningResultValue(screeningResult, 'alcohol_drugs'),
    eatingdisorder: getScreeningResultValue(screeningResult, 'eatingdisorder'),
    diabetesType1: getScreeningResultValue(screeningResult, 'diabetesType1'),
    diabetesType2: getScreeningResultValue(screeningResult, 'diabetesType2'),
  });
};

export const getEligibilityForCheckoutData = (
  screeningResult: ScreeningResult,
): FunnelPageData<typeof EligibilityQuestionsForCheckout> => {
  return removeUndefinedFields({
    diabetesType1: getScreeningResultValue(screeningResult, 'diabetesType1'),
    heartFailure: getScreeningResultValue(screeningResult, 'heartFailure'),
    severeLiverOrKidneyDisease: getScreeningResultValue(screeningResult, 'severeLiverOrKidneyDisease'),
    multipleEndocrineNeoplasiaType2: getScreeningResultValue(screeningResult, 'multipleEndocrineNeoplasiaType2'),
    historyOfInflammatoryBowelDiseaseOrGastroparesis: getScreeningResultValue(
      screeningResult,
      'historyOfInflammatoryBowelDiseaseOrGastroparesis',
    ),
    cancer: getScreeningResultValue(screeningResult, 'cancer'),
    activePancreatitisOrGallstones: getScreeningResultValue(screeningResult, 'activePancreatitisOrGallstones'),
    eatingdisorder: getScreeningResultValue(screeningResult, 'eatingdisorder'),
    alcohol_drugs: getScreeningResultValue(screeningResult, 'alcohol_drugs'),
  });
};

export const getComorbidityData = (screeningResult: ScreeningResult): FunnelPageData<typeof ComorbidityQuestions> => {
  return removeUndefinedFields({
    sleepapnea: getScreeningResultValue(screeningResult, 'sleepapnea'),
    hypertension: getScreeningResultValue(screeningResult, 'hypertension'),
    prediabetes: getScreeningResultValue(screeningResult, 'prediabetes'),
    cardiovascular: getScreeningResultValue(screeningResult, 'cardiovascular'),
    highcolesterol: getScreeningResultValue(screeningResult, 'highcolesterol'),
  });
};

export const getPastWeightLossSolutionsData = (
  pastWeightLossSolutions: UserGetQuery['User']['client']['pastWeightLossSolutions'],
): FunnelPageData<typeof PastWeightLossSolutions> => {
  return {
    NONE: pastWeightLossSolutions.includes(WeightLossSolution.None),
    DIETING: pastWeightLossSolutions.includes(WeightLossSolution.Dieting),
    COACHING: pastWeightLossSolutions.includes(WeightLossSolution.Coaching),
    MEDICATION: pastWeightLossSolutions.includes(WeightLossSolution.Medication),
    MOBILE_APPS: pastWeightLossSolutions.includes(WeightLossSolution.MobileApps),
    ONLINE_PROGRAMS: pastWeightLossSolutions.includes(WeightLossSolution.OnlinePrograms),
    PHYSICAL_TRAINING: pastWeightLossSolutions.includes(WeightLossSolution.PhysicalTraining),
  };
};

export const getMotivationsData = (
  motivations: UserGetQuery['User']['client']['motivations'],
): FunnelPageData<typeof MotivationsV2> => {
  return {
    OTHER: motivations.includes(ClientMotivation.Other),
    MORE_ENERGY: motivations.includes(ClientMotivation.MoreEnergy),
    SUSTAINABLE_WEIGHT_LOSS: motivations.includes(ClientMotivation.SustainableWeightLoss),
    IMPROVE_PHYSICAL_HEALTH: motivations.includes(ClientMotivation.ImprovePhysicalHealth),
    LOWER_RISK_OF_DISEASES: motivations.includes(ClientMotivation.LowerRiskOfDiseases),
    FITNESS_AND_CONFIDENCE: motivations.includes(ClientMotivation.FitnessAndConfidence),
    IMPROVE_MENTAL_HEALTH: motivations.includes(ClientMotivation.ImproveMentalHealth),
    HEALTHIER_LIFESTYLE: motivations.includes(ClientMotivation.HealthierLifestyle),
  };
};
