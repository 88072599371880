import { Image, Stack, Text } from '@mantine/core';
import { QuoteComponent } from '@shared/components/QuoteComponent';
import { IFunnelLoaderConfig } from '@shared/funnel-engine';
import { EmployeeData } from '@shared/types';
import { FunctionComponent } from 'react';

import { getEmployeeByType, getLoadingTimeMsByType } from './employeeData';
import { useIndexStepper } from '../hooks/useIndexStepper';

const EmployeeWithTitleAndQuote: FunctionComponent<{
  getEmployee: () => EmployeeData;
}> = ({ getEmployee }) => {
  const { quotes, imageSrc, name, title } = getEmployee();
  const { currentIndex, currentStepTime: stepTime } = useIndexStepper(quotes.map((q) => q.time));

  return (
    <Stack align="center" mih={350} gap={0}>
      <Stack h={100}>
        <Image src={imageSrc} h={100} w="100px" radius={'md'} />
      </Stack>
      {quotes.map((q, i) => (
        <QuoteComponent key={`qoute+${i}`} {...q} isMounted={currentIndex === i} totalTime={stepTime} />
      ))}
      <Text ta="center" fw={600} size={'lg'}>
        {name}
      </Text>
      <Text ta="center" data-tertiary fw={400} size={'lg'}>
        {title}
      </Text>
    </Stack>
  );
};

export const employeeLoaderCoach: IFunnelLoaderConfig = {
  component: EmployeeWithTitleAndQuote.bind(null, {
    getEmployee: () => getEmployeeByType('COACH'),
  }),
  minimumDurationSeconds: getLoadingTimeMsByType('COACH') / 1000,
};
