import React from 'react';
import { useTranslation } from 'react-i18next';

import { EmblaLink } from './EmblaLink';

const manageCookies = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  event.preventDefault();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)?.Cookiebot?.renew();
};

export const ManageCookiesFooter: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();

  return (
    <EmblaLink href="#" size="xxs" onClick={manageCookies} ta={'center'}>
      {t('linkText.manageCookies')}
    </EmblaLink>
  );
};
