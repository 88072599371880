import {
  CountryIso3166,
  FinancialPrice,
  HeardAboutEmbla,
  LocaleIso6391,
  PartnerClientSession,
  PartnerInfo,
  PublicBookableScreeningAppointment,
  TimeOfDay,
  UnitSystem,
  UserGetQuery,
} from '@shared/gql/sdk';

export enum ProductSelection {
  MedicationAndCoaching,
  CoachingAndMaybeMedication,
  CoachingOnly,
  MedicationTaperingOff,
}

export type FlowVariant =
  | 'booking'
  | 'callback'
  | 'checkout'
  | 'phone-already-registered'
  | 'watch-list-for-higher-dosage'
  | 'watch-list-screening-capacity';

export type NotEligibleReason = 'low-bmi' | 'very-low-bmi' | 'eating-disorder' | 'addiction';

interface IFunnelContext {
  noUserDiscount?: boolean;
  clientPartnerAccessCode?: string;
  loginLinkSent?: boolean;
  partnerCode?: string;
  partnerInfo?: PartnerInfo;
  partnerClientSession?: PartnerClientSession;
  locale?: LocaleIso6391;
  country?: CountryIso3166;
  appointmentId?: string;
  notEligibleReason?: NotEligibleReason;
  embedded?: boolean;
  flowVariant?: FlowVariant;
  priceInfos?: FinancialPrice[];
  prefilledDiscountCode?: string;
  userSession?: {
    token: string;
    refreshToken?: string;
    userId: string;
    phoneNumber: string;
    phoneConfirmed: boolean;
    firstName?: string;
    email?: string;
    emailMarketing?: boolean;
  };
  measurements?: {
    bmi: number;
    height: number;
    weight: number;
    preferredUnitSystem: UnitSystem;
  };
  onGLP1Medication?: boolean;
  initialEligibility?: ProductSelection;
  heardAbout?: HeardAboutEmbla;
  eligibleFor?: ProductSelection;
  selectedProduct?: ProductSelection;
  selectedProductPriceInfo?: FinancialPrice;
  bookableAppointments?: PublicBookableScreeningAppointment[];
  selectedTimeslot?: PublicBookableScreeningAppointment;
  timeOfDay?: TimeOfDay;
  features?: {
    higherDoseWaitlist: boolean;
  };
  stripeCredentials?: {
    publicKey: string;
    clientSecret: string;
  };
  source?: 'APP' | 'PHARMACY' | 'SIGNUP-FUNNEL';
}

export interface IFunnelSession {
  context: IFunnelContext;
  user?: UserGetQuery['User'];
}

export default IFunnelContext;
