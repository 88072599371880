import { Card, Group } from '@mantine/core';
import { CountryIso3166 } from '@shared/gql/sdk';
import { useTheme } from '@shared/theme';
import React from 'react';

import { resolveIntl } from '../helpers';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

export const TrustBox = () => {
  const { theme } = useTheme();
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.

    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  const intl = resolveIntl();
  const getUrl = () => {
    const country = intl.country;
    if (country === CountryIso3166.Dk) {
      return 'https://dk.trustpilot.com/review/joinembla.com';
    }
    return 'https://uk.trustpilot.com/review/joinembla.com';
  };

  if (!window.Trustpilot) {
    return null;
  }

  return (
    <Group justify={'center'}>
      <Card shadow="sm">
        <div
          ref={ref}
          className="trustpilot-widget"
          data-locale={`${intl.locale}-${intl.country}`}
          data-template-id="5419b732fbfb950b10de65e5"
          data-businessunit-id="61f6a77c352a9df5308925e7"
          data-style-height="24px"
          data-style-width="100%"
          data-theme="light"
          data-font-family="Muli"
          data-text-color={theme.other.theme.screen.primary}
        >
          <a href={getUrl()} target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      </Card>
    </Group>
  );
};

export const TrustBoxMicroTrustScore = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.

    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  const intl = resolveIntl();

  if (!window.Trustpilot) {
    return null;
  }

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={`${intl.locale}-${intl.country}`}
      data-template-id="5419b637fa0340045cd0c936"
      data-businessunit-id="61f6a77c352a9df5308925e7"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="dark"
    >
      <a href="https://www.trustpilot.com/review/joinembla.com" target="_blank" rel="noopener">
        Trustpilot
      </a>
    </div>
  );
};

//
