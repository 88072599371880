import { FunnelRoutes } from '@shared/funnel-engine';
import { NonEligibleReason, TrackingEventName } from '@shared/gql/sdk';
import { trackingService } from '@shared/TrackingContext';

import IFunnelContext, { ProductSelection } from '../../../FunnelContext';
import { createPartnerFunnelConfig } from '../config';

type Pages = FunnelRoutes<ReturnType<typeof createPartnerFunnelConfig>['config']>;

const { trackEvent, pushGTMEvent } = trackingService;

// const mapEligibleToTrackingProduct = (eligibleFor: ProductSelection) => {
//   switch (eligibleFor) {
//     case ProductSelection.MedicationAndCoaching:
//     case ProductSelection.CoachingAndMaybeMedication:
//       return 'COACHING_AND_MEDICATION';
//     case ProductSelection.CoachingOnly:
//       return 'COACHING';
//   }
// };

const mapEligibleToTrackingEligibility = (eligibleFor: ProductSelection) => {
  switch (eligibleFor) {
    case ProductSelection.MedicationAndCoaching:
      return 'MedicineAndCoaching';
    case ProductSelection.CoachingAndMaybeMedication:
      return 'CoachingAndMaybeMedicine';
    case ProductSelection.CoachingOnly:
      return 'CoachingOnly';
    default:
      return 'NotEligible';
  }
};

export const onPageEntry = (page: Pages) => {
  pushGTMEvent('pageview', { funnel_page: page });
};

export const onPageExit = (
  page: Pages,
  context: IFunnelContext,
  // data: FunnelEngineData<ReturnType<typeof createPartnerFunnelConfig>['config']>,
) => {
  switch (page) {
    case 'registration/glp1':
      trackEvent(TrackingEventName.SignupGlp1Answered, {
        GLP1_at_signup: context.onGLP1Medication,
      });
      break;
    case 'registration/comorbidity':
      trackEvent(TrackingEventName.SignupComorbidityQuestionsAnswered);
      break;
    case 'registration/eligibility':
      {
        trackEvent(TrackingEventName.SignupEligibilityQuestionsAnswered);
        const reasons = [];
        if (context.notEligibleReason === 'addiction') {
          reasons.push('AlcoholDrugs');
        }
        if (context.notEligibleReason === 'eating-disorder') {
          reasons.push('EatingDisorder');
        }
        trackEvent(TrackingEventName.SignupScreeningCompleted, {
          bmi: context.measurements.bmi,
          eligibility_result: mapEligibleToTrackingEligibility(context.eligibleFor),
          reasons,
        });
      }
      break;
    case 'registration/height_weight':
      trackEvent(TrackingEventName.SignupHeightWeightEntered, {
        bmi: context.measurements.bmi,
        weight: context.measurements.weight,
        unitSystem: context.measurements.preferredUnitSystem,
      });
      trackEvent(TrackingEventName.SignupUnitSystemPicked, {
        unit_system: context.measurements.preferredUnitSystem,
      });
      if (context.measurements.bmi < 27) {
        trackEvent(TrackingEventName.SignupScreeningCompleted, {
          bmi: context.measurements.bmi,
          eligibility_result: mapEligibleToTrackingEligibility(context.eligibleFor),
          reasons: NonEligibleReason.BmiTooLow,
        });
      }
      break;
  }
};
