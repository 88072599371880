import { ReactComponent as IconCheckbox } from '@assets/icons/icon-checkbox.svg';
import { Stack, Text } from '@mantine/core';
import { FeedbackLink } from '@shared/components/buttons/FeedbackLink';
import { GrowingButton } from '@shared/components/buttons/GrowingButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { getRootPath } from '@shared/helpers';
import { Trans, useTranslation } from 'react-i18next';

import { MembershipSummary } from './Checkout/MembershipSummary';
import { FaqCard } from './components/FaqCard';
import IFunnelContext from '../FunnelContext';

export const ConfirmEmbeddedWithMembershipSummary: FunnelPageComponent<
  { userPhoneNumber: string; phoneChanged?: boolean },
  IFunnelContext
> = ({ context: { userSession, selectedProductPriceInfo, source, embedded } }) => {
  const { t } = useTranslation();

  return (
    <FullScreenContainer>
      <Stack gap={'md'} align="center">
        <IconCheckbox height={40} />
        <>
          <Text size={'30px'} lh={'38px'} ta={'center'}>
            <Trans
              i18nKey="yourPaymentIsConfirmed"
              components={{
                line: <span style={{ display: 'block' }} />,
                dark: <span />,
                light: <span />,
              }}
            />
          </Text>
          <Text size="lg" ta={'center'}>
            {t('weHaveSentAReceiptTo', { email: userSession.email })}
          </Text>
          <Text size="lg" ta={'center'}>
            {t('excitedAboutYourMembership')}
          </Text>
        </>
      </Stack>
      <MembershipSummary price={selectedProductPriceInfo} />
      <FaqCard />
      <BottomScreenContainer>
        <GrowingButton
          data-testid="next"
          type="button"
          onClick={() => {
            window.location.href = `${getRootPath()}/app-complete`;
          }}
          buttonLabel={t('finish')}
        />

        <FeedbackLink embedded={embedded} source={source} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
