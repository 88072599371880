import { FunnelRoutes } from '@shared/funnel-engine';
import { HeardAboutEmbla, NonEligibleReason, TrackingEventName } from '@shared/gql/sdk';
import { trackingService } from '@shared/TrackingContext';
import { Data } from 'src/v3/configs/uk-checkout-or-book/handlers/data';

import IFunnelContext, { ProductSelection } from '../../../FunnelContext';
import { createV3FunnelConfig } from '../config-callback-request';

type PagesCallbackRequest = FunnelRoutes<ReturnType<typeof createV3FunnelConfig>['config']>;
type Pages = PagesCallbackRequest;

const { trackEvent, pushGTMEvent } = trackingService;

const mapEligibleToTrackingEligibility = (eligibleFor: ProductSelection) => {
  switch (eligibleFor) {
    case ProductSelection.MedicationAndCoaching:
      return 'MedicineAndCoaching';
    case ProductSelection.CoachingAndMaybeMedication:
      return 'CoachingAndMaybeMedicine';
    case ProductSelection.CoachingOnly:
      return 'CoachingOnly';
    default:
      return 'NotEligible';
  }
};

export const onPageEntry = (page: Pages, context: IFunnelContext) => {
  pushGTMEvent('pageview', { funnel_page: page });
  switch (page) {
    case 'program/next_day':
      trackEvent(TrackingEventName.SignupStarted);
      trackEvent('signup_next_day_presented');
      break;

    case 'program/registration':
      trackEvent('signup_registration_presented');
      break;
    case 'checkout/confirm_membership_phone':
      trackEvent(TrackingEventName.SignupBookingcodeAsked);
      break;
    case 'checkout/recommendation':
      trackEvent(TrackingEventName.SignupScreeningCompleted, {
        bmi: context.measurements.bmi,
        eligibility_result: mapEligibleToTrackingEligibility(context.eligibleFor),
      });
      trackEvent('signup_membership_recommended');
      break;
    case 'checkout/checkout_or_book':
      trackEvent('signup_option_chosen');
      break;
    case 'checkout/pay':
      trackEvent('signup_checkout_started');
      break;
    case 'checkout/confirm_booking_phone':
      trackEvent('signup_bookingcode_entered');
      break;
    case 'checkout/confirmation_booking':
      if (context.flowVariant === 'booking') {
        trackEvent(TrackingEventName.SignupConsultationConfirmed, {
          bookedAppointmentId: context.appointmentId,
        });
        pushGTMEvent('screening_booked', {
          bookedAppointmentId: context.appointmentId,
        });
      } else if (context.flowVariant === 'callback') {
        trackEvent('signup_callback_confirmed');
      }

      break;
  }
};

export const onPageExit = (page: Pages, context: IFunnelContext, data: Data) => {
  // data.program.biggest_lie = context.biggestLie;
  switch (page) {
    case 'program/registration':
      if (context.userSession?.userId) {
        trackEvent(TrackingEventName.SignupRegistered);
        pushGTMEvent('user_registered', {
          userId: context.userSession.userId,
        });
      }
      break;
    case 'eligibility/glp1':
      trackEvent(TrackingEventName.SignupGlp1Answered, {
        GLP1_at_signup: context.onGLP1Medication,
      });
      break;
    case 'checkout/confirm_membership_phone':
      trackEvent(TrackingEventName.SignupPurchaseConfirmed);
      break;
    case 'program/heard_about':
      {
        const heardAboutMap: Partial<Record<HeardAboutEmbla, string>> = {
          [HeardAboutEmbla.Emails]: 'emails',
          [HeardAboutEmbla.Facebook]: 'facebook',
          [HeardAboutEmbla.FriendReferal]: 'aFriendReferredMe',
          [HeardAboutEmbla.Google]: 'google',
          [HeardAboutEmbla.Instagram]: 'instagram',
          [HeardAboutEmbla.NotSure]: 'iAmNotSure',
          [HeardAboutEmbla.TvAdvert]: 'tvAdvert',
          [HeardAboutEmbla.Youtube]: 'youtube',
          [HeardAboutEmbla.NewsArticle]: 'newsArticle',
          [HeardAboutEmbla.GoMorgenDanmark]: 'goMorgenDanmark',
          [HeardAboutEmbla.Pharmacy]: 'pharmacy',
        };

        const heardAboutMappedToString = heardAboutMap[data.program.heard_about.value];

        trackEvent(TrackingEventName.SignupHeardAboutAnswered, {
          answer: heardAboutMappedToString,
        });
      }
      break;
    case 'eligibility/sex_assigned':
      trackEvent(TrackingEventName.SignupSexAssignedAnswered);
      break;
    case 'eligibility/pregnancy':
      trackEvent(TrackingEventName.SignupPregnancyAnswered);
      break;
    case 'eligibility/current_medications':
      trackEvent(TrackingEventName.SignupMedicationsAnswered);
      break;
    case 'eligibility/medication_wanted':
      trackEvent(TrackingEventName.SignupMedicationWantedAnswered);
      break;
    case 'eligibility/comorbidity':
      trackEvent(TrackingEventName.SignupComorbidityQuestionsAnswered);
      break;
    case 'eligibility/eligibility':
      {
        trackEvent(TrackingEventName.SignupEligibilityQuestionsAnswered);
        const reasons = [];
        if (context.notEligibleReason === 'addiction') {
          reasons.push('AlcoholDrugs');
        }
        if (context.notEligibleReason === 'eating-disorder') {
          reasons.push('EatingDisorder');
        }
        if (reasons.length > 0) {
          trackEvent(TrackingEventName.SignupScreeningCompleted, {
            bmi: context.measurements.bmi,
            eligibility_result: mapEligibleToTrackingEligibility(context.eligibleFor),
            reasons,
          });
        }
      }
      break;
    case 'program/height_weight':
      trackEvent(TrackingEventName.SignupHeightWeightEntered, {
        bmi: context.measurements.bmi,
        weight: context.measurements.weight,
        unitSystem: context.measurements.preferredUnitSystem,
      });
      trackEvent(TrackingEventName.SignupUnitSystemPicked, {
        unit_system: context.measurements.preferredUnitSystem,
      });
      if (context.measurements.bmi < 27) {
        trackEvent(TrackingEventName.SignupScreeningCompleted, {
          bmi: context.measurements.bmi,
          eligibility_result: mapEligibleToTrackingEligibility(context.eligibleFor),
          reasons: NonEligibleReason.BmiTooLow,
        });
      }
      break;
    case 'checkout/pick_time_of_the_day':
      trackEvent('signup_callback_requested', {
        time_of_day: context.timeOfDay,
      });
      break;
    case 'checkout/pay':
      trackEvent(TrackingEventName.SignupCheckoutCompleted, {
        currency: context.selectedProductPriceInfo.currency,
        amount: context.selectedProductPriceInfo.amount,
      });
      break;
  }
};
