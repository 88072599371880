import { Anchor, AnchorProps } from '@mantine/core';
import { FunctionComponent, HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';

import classes from './css-modules/EmblaLink.module.css';

interface IProps extends AnchorProps {
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  target?: HTMLAttributeAnchorTarget;
}

export const EmblaLink: FunctionComponent<PropsWithChildren<IProps>> = ({
  href,
  onClick,
  target,
  children,
  ...props
}) => {
  return (
    <Anchor underline={'always'} target={target} href={href} onClick={onClick} className={classes.emblaLink} {...props}>
      {children}
    </Anchor>
  );
};
