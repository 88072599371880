import { Text, Title } from '@mantine/core';
import api from '@shared/api';
import { funnelConfiguration, funnelPage } from '@shared/funnel-engine';
import { MedicationWantedAnswers, PartnerClientSessionType, SexAssignedAtBirth } from '@shared/gql/sdk';
import { calculateBmi, resolveIntl } from '@shared/helpers';
import { Trans } from 'react-i18next';
import { t } from 'src/i18n';
import IFunnelContext, { NotEligibleReason, ProductSelection } from 'src/v3/FunnelContext';
import { resolveRecommendationLoader } from 'src/v3/loaders/recommendationLoader';
import { BirthDate } from 'src/v3/pages/BirthDate';
import { Checkout } from 'src/v3/pages/Checkout/Checkout';
import { Pay } from 'src/v3/pages/Checkout/Pay';
import { Recommendation } from 'src/v3/pages/Checkout/Recommendation';
import { ComorbidityQuestions } from 'src/v3/pages/ComorbidityQuestions';
import { ConfirmPhoneNumber } from 'src/v3/pages/ConfirmPhoneNumber';
import { CurrentGLP1Medication } from 'src/v3/pages/CurrentGLP1Medication';
import { CurrentMedications } from 'src/v3/pages/CurrentMedications';
import { EligibilityQuestions } from 'src/v3/pages/EligibilityQuestions';
import { MedicationWanted } from 'src/v3/pages/MedicationWanted';
import { NotEligible } from 'src/v3/pages/NotEligible';
import { PageTemplates } from 'src/v3/pages/pageTemplates';
import { WhoCanWeHelp } from 'src/v3/pages/PartnerCheckup/WhoCanWeHelp';
import { PickTimeslot } from 'src/v3/pages/PickTimeslot';
import { Pregnancy } from 'src/v3/pages/Pregnancy';
import { Registration, RegistrationErrors } from 'src/v3/pages/Registration';
import { SexAssigned } from 'src/v3/pages/SexAssigned';
import { anyTicked, getPriceInfoByProductSelection, onlyTickedOneOrMore } from 'src/v3/util/helpers';
import { performPasswordLessLogin, startPasswordlessLogin } from 'src/v3/util/passwordLessLogin';

import { saveData } from './handlers/data';
import { registrationExitHandler } from './handlers/registration';
import { onPageEntry, onPageExit } from './handlers/tracking';
import { HeightWeight } from '../../pages/HeightWeight';

const titleElement = (key: string) => {
  return (
    <Title fw={400}>
      <Trans components={{ strong: <Title display="inline" /> }} i18nKey={key} />
    </Title>
  );
};
const textElement = (key: string) => {
  return (
    <Text>
      <Trans components={{ strong: <b /> }} i18nKey={key} />
    </Text>
  );
};

export const createPartnerFunnelConfig = (initialContext: Partial<IFunnelContext>) =>
  funnelConfiguration({
    handlers: {
      onPageExit: ({ page, context, data, isReplay }) => {
        if (isReplay) return;

        onPageExit(page, context);
        saveData(data, context);
      },
      onPageEntry: async ({ page, isReplay }) => {
        if (isReplay) return;

        onPageEntry(page);
      },
      onComplete: async ({ isReplay }) => {
        if (isReplay) return;

        // if (page === 'booking/confirmation') {
        //   await saveData(data, context, true);
        // }
      },
    },
    config: {
      intro: {
        perspective: funnelPage(
          PageTemplates.TitleText({
            title: titleElement('perspective.title'),
          }),
          {
            pageWidth: 500,
          },
        ),
        how_we_help: funnelPage(
          PageTemplates.TitleBulletPoints({
            title: t('how_we_help.title'),
            bulletpoints: [
              textElement('personalHealthCoach'),
              textElement('appWithTools'),
              textElement('medicationAsSupport'),
              textElement('accessToMedicalStaff'),
              textElement('onlinePlan'),
            ],
          }),
          { pageWidth: 500 },
        ),
        results: funnelPage(
          PageTemplates.TitleBulletPoints({
            title: t('results.title'),
            bulletpoints: [
              textElement('averageWeightloss'),
              textElement('lowerDosage'),
              textElement('increasedHappiness'),
              textElement('weightLossRetention'),
            ],
          }),
          { pageWidth: 500 },
        ),
        who_can_we_help: funnelPage(WhoCanWeHelp, { pageWidth: 500 }),
        what_can_we_offer: funnelPage(
          PageTemplates.TitleBulletPoints({
            title: t('what_can_we_offer.title'),
            bulletpoints: [
              textElement('freeIntroCall'),
              textElement('takeMeasurements'),
              textElement('accessToCheckups'),
              textElement('accessToApp'),
            ],
          }),
          { pageWidth: 500 },
        ),
      },
      registration: {
        registration: funnelPage(Registration, {
          onExit: registrationExitHandler,
        }),
        confirm_phone_number: funnelPage(ConfirmPhoneNumber, {
          onEntry: async ({
            context: {
              userSession: { phoneNumber },
            },
          }) => await startPasswordlessLogin(phoneNumber),
          onExit: async ({ context, funnelApi }) => {
            const { User } = await api.UserGet();

            if (User) {
              const activeScreening = User?.activeScreening;
              if (activeScreening) {
                funnelApi.updateContext({ appointmentId: activeScreening.id });
              }
              const { CreatePartnerClientSession: partnerClientSession } = await api.CreatePartnerClientSession({
                type: PartnerClientSessionType.Signup,
                partnerCode: context.partnerCode,
              });

              funnelApi.updateContext({ partnerClientSession });
            }
          },
          actions: {
            confirmPhoneNumber: async (phoneNumber: string, code: string, { funnelApi }) => {
              const result = await performPasswordLessLogin(phoneNumber, code);
              if (!result) {
                return false;
              }

              sessionStorage.setItem('token', result.token);

              funnelApi.updateContext({
                userSession: {
                  phoneNumber,
                  token: result.token,
                  refreshToken: result.refreshToken,
                  userId: result.userId,
                  phoneConfirmed: true,
                },
                flowVariant: 'booking',
              });

              return true;
            },
            sendSMSCode: startPasswordlessLogin,
          },
        }),
        birthDate: funnelPage(BirthDate),
        height_weight: funnelPage(HeightWeight, {
          onExit: async ({ data: { height, weight, unitSystem }, funnelApi }) => {
            const bmi = calculateBmi(weight, height);
            const getLowBmiReason = (): NotEligibleReason => {
              if (bmi < 18.5) return 'very-low-bmi';
              if (bmi < 27) return 'low-bmi';
            };

            funnelApi.updateContext({
              measurements: {
                bmi,
                height,
                weight,
                preferredUnitSystem: unitSystem,
              },
              notEligibleReason: getLowBmiReason(),
            });
          },
        }),
        eligibility: funnelPage(EligibilityQuestions, {
          onExit: async ({ funnelApi, context: { initialEligibility }, data }) => {
            if (data.alcohol_drugs || data.eatingdisorder) {
              funnelApi.updateContext({
                notEligibleReason: data.eatingdisorder ? 'eating-disorder' : 'addiction',
                eligibleFor: undefined,
              });
              return;
            } else if (anyTicked(data) && !onlyTickedOneOrMore(data, ['diabetesType2', 'cancer'])) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            } else {
              // else just reset back to whatever was seeded
              funnelApi.updateContext({
                eligibleFor: initialEligibility,
                selectedProduct: initialEligibility,
                notEligibleReason: undefined,
              });
            }
          },
        }),
        comorbidity: funnelPage(ComorbidityQuestions, {
          onExit: async ({
            context: {
              measurements: { bmi },
            },
            data,
            funnelApi,
          }) => {
            const fallbackProduct = bmi >= 30 ? ProductSelection.MedicationAndCoaching : ProductSelection.CoachingOnly;

            const product = anyTicked(data) ? ProductSelection.MedicationAndCoaching : fallbackProduct;

            funnelApi.updateContext({
              initialEligibility: product,
            });
          },
        }),
        sex_assigned: funnelPage(SexAssigned),
        pregnancy: funnelPage(Pregnancy, {
          loader: ({ data: { value } }) => {
            if (value) {
              return resolveRecommendationLoader(ProductSelection.CoachingOnly);
            }
          },
          onExit: async ({ data: { value }, funnelApi }) => {
            if (value) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            } else {
              funnelApi.updateContext({
                eligibleFor: undefined,
                selectedProduct: undefined,
                notEligibleReason: undefined,
              });
            }
          },
        }),
        current_medications: funnelPage(CurrentMedications, {
          loader: ({ data }) => {
            if (!data.NONE) {
              return resolveRecommendationLoader(ProductSelection.CoachingOnly);
            }
          },
          onExit: async ({ data, funnelApi }) => {
            if (data.NONE) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.MedicationAndCoaching,
                selectedProduct: ProductSelection.MedicationAndCoaching,
                notEligibleReason: undefined,
              });
            } else {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            }
          },
        }),
        glp1: funnelPage(CurrentGLP1Medication, {
          onExit: async ({ context, data, funnelApi }) => {
            const getLowBmiReason = (): NotEligibleReason => {
              if (context.measurements.bmi < 18.5) return 'very-low-bmi';
              if (context.measurements.bmi < 27) return 'low-bmi';
            };
            if (context.measurements.bmi < 27) {
              if (data.value) {
                funnelApi.updateContext({
                  eligibleFor: ProductSelection.MedicationTaperingOff,
                  selectedProduct: ProductSelection.MedicationTaperingOff,
                });
              } else {
                funnelApi.updateContext({
                  eligibleFor: undefined,
                  notEligibleReason: getLowBmiReason(),
                });
              }
            }
          },
        }),
        medication_wanted: funnelPage(MedicationWanted, {
          onExit: async ({ data, funnelApi }) => {
            if (data.value === MedicationWantedAnswers.No) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            } else {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.MedicationAndCoaching,
                selectedProduct: ProductSelection.MedicationAndCoaching,
                notEligibleReason: undefined,
              });
            }
          },
          loader: ({ data: { value } }) =>
            resolveRecommendationLoader(
              value === MedicationWantedAnswers.No
                ? ProductSelection.CoachingOnly
                : ProductSelection.MedicationAndCoaching,
            ),
        }),
        thank_you: funnelPage(NotEligible, { terminal: true }),
      },
      checkout: {
        recommendation: funnelPage(Recommendation, {
          onEntry: async ({
            context: { selectedProduct, priceInfos: initialPriceInfos, prefilledDiscountCode },
            funnelApi,
          }) => {
            const { locale } = resolveIntl();
            const priceInfos = initialPriceInfos
              ? initialPriceInfos
              : (
                  await api.FinancialPrices({
                    locale,
                    promoCode: prefilledDiscountCode,
                  })
                ).FinancialPrices;
            funnelApi.updateContext({
              priceInfos,
              selectedProductPriceInfo: getPriceInfoByProductSelection(selectedProduct, priceInfos),
            });
          },
          onExit: async ({ data, funnelApi, context }) => {
            funnelApi.updateContext({
              selectedProduct: data.selectedProduct,
              selectedProductPriceInfo: getPriceInfoByProductSelection(data.selectedProduct, context.priceInfos),
            });
          },
        }),
        checkout: funnelPage(Checkout, {
          onExit: async ({ data: { selectedProduct }, funnelApi, context }) => {
            funnelApi.updateContext({
              selectedProduct: selectedProduct,
              selectedProductPriceInfo: getPriceInfoByProductSelection(selectedProduct, context.priceInfos),
            });
          },
        }),
        pay: funnelPage(Pay, {
          onEntry: async ({ funnelApi, context }) => {
            const subscriptionSetupResponse = await api.SubscriptionSetup({
              priceId: context.selectedProductPriceInfo.id,
            });
            funnelApi.updateContext({
              stripeCredentials: subscriptionSetupResponse.UserSubscriptionSetup,
            });
          },
        }),
        pick_timeslot: funnelPage(PickTimeslot, {
          onEntry: async ({ funnelApi }) => {
            const results = await api.BookableScreeningAppointments();
            funnelApi.updateContext({
              bookableAppointments: results.BookableScreeningAppointments || [],
            });
          },
          onExit: async ({ data: { selectedTimeslot }, funnelApi }) => {
            funnelApi.updateContext({ selectedTimeslot });
          },
        }),
      },
    },
    routingRules: {
      registration: {
        registration: ({ context: { flowVariant }, error }) => {
          if (error === RegistrationErrors.PhoneOrEmailAlreadyInUse) {
            return 'registration/registration';
          }
          if (flowVariant === 'phone-already-registered') return 'registration/confirm_phone_number';
          else return 'registration/height_weight';
        },
        height_weight: ({ context: { notEligibleReason } }) => {
          if (['low-bmi', 'very-low-bmi'].includes(notEligibleReason)) {
            return 'registration/thank_you';
          }
        },
        sex_assigned: ({ data }) =>
          data.value === SexAssignedAtBirth.Male ? 'registration/current_medications' : 'registration/pregnancy',
        medication_wanted: () => 'checkout/recommendation',
      },
      checkout: {
        recommendation: ({ data }) => {
          if (data.productChanged) {
            return 'checkout/recommendation';
          }
        },
      },
    },
    defaultPageWidth: 720,
    initialContext,
    defaultLoader: {
      component: () => null,
      progressBar: false,
    },
  });
