import { Stack, Text, Title } from '@mantine/core';
import { GrowingButton } from '@shared/components/buttons/GrowingButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/v3/FunnelContext';

export const Welcome: FunnelPageComponent<{ continue: boolean }, IFunnelContext> = ({ context, funnelApi }) => {
  const { t } = useTranslation();
  const {
    partnerClientSession: {
      clientInfo: { firstName },
    },
    partnerInfo: { name },
  } = context;
  return (
    <FullScreenContainer>
      <Stack gap="lg">
        <Title ta="center">{t('welcome', { name: firstName })}</Title>
        <Text ta="center" data-secondary-font>
          {t('welcome.description', { partnerName: name })}
        </Text>
        <BottomScreenContainer>
          <NextButton label={t('ok')} grow onClick={() => funnelApi.next({ continue: true })} />
          <GrowingButton
            forceGrow
            buttonLabel={t('goBack')}
            onClick={() => funnelApi.next({ continue: false })}
            variant="outline"
          />
        </BottomScreenContainer>
      </Stack>
    </FullScreenContainer>
  );
};
