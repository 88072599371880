import EmblaCoach from '@assets/images/embla-coach.png';
import { Card, Checkbox, Group, Image, Stack, Text, UnstyledButton } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { TrustBox } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { Address } from '@shared/gql/sdk';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/v3/FunnelContext';

import { AddressForm } from './AddressForm';
import { Benefits } from './Benefits';
import { MembershipSummary } from './MembershipSummary';
import { YourRecommendedMembership } from './YourRecommendedMembership';
import { SwitchProgram } from '../Program/SwitchProgram';

export const CheckoutOrCallback: FunnelPageComponent<
  {
    address: Address;
    didSelectCallback: boolean;
    confirmation: boolean;
    selectedProduct: ProductSelection;
    productChanged?: boolean;
  },
  IFunnelContext
> = ({
  data: { address, didSelectCallback: initialDidSelectCallback, confirmation: initialConfirmation },
  context: { selectedProduct, selectedProductPriceInfo: price, eligibleFor },
  funnelApi,
}) => {
  const { t } = useTranslation();
  const shippingAddress = useRef(address);
  const addressFormRef = useRef<{ validate: () => boolean }>();

  const [didSelectCallback, setDidSelectCallback] = useState(
    initialDidSelectCallback !== undefined ? initialDidSelectCallback : false,
  );
  const [_confirmation, setConfirmation] = useState(initialConfirmation);

  const confirmation = didSelectCallback || _confirmation;

  return (
    <FullScreenContainer>
      <StepTitle title={<Trans i18nKey={'yourFutureSelfWillThankYou'} />} />
      <Stack gap="lg">
        <YourRecommendedMembership
          price={price}
          selectedProduct={selectedProduct}
          selected={!didSelectCallback}
          onClick={() => setDidSelectCallback(false)}
          compact
        />

        {eligibleFor === ProductSelection.MedicationAndCoaching && (
          <SwitchProgram
            productChanged={true}
            selectedProduct={selectedProduct}
            onClick={() => {
              funnelApi.next({
                selectedProduct:
                  selectedProduct == ProductSelection.CoachingOnly
                    ? ProductSelection.MedicationAndCoaching
                    : ProductSelection.CoachingOnly,
                productChanged: true,
                address: shippingAddress.current,
                didSelectCallback,
                confirmation,
              });
            }}
          />
        )}

        <Stack gap="0">
          <Text py="xs" fw="bold">
            {t('or')}
          </Text>
          <RequestCallbackCard selected={didSelectCallback} onClick={() => setDidSelectCallback(true)} />
        </Stack>

        {!didSelectCallback && (
          <>
            <AddressForm
              ref={addressFormRef}
              label={t('deliveryAddress')}
              address={address}
              onChange={(changedAddress) => {
                shippingAddress.current = changedAddress;
              }}
            />
            <MembershipSummary price={price} />
          </>
        )}

        <TrustBox />
        {!didSelectCallback && (
          <>
            <Benefits selectedProduct={selectedProduct} />
            <Checkbox
              className="stripe-checkbox"
              radius="5"
              label={<strong>{t('checkoutConfirmationDescription')}</strong>}
              checked={confirmation}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => {
                setConfirmation(e.target.checked);
              }}
            />
          </>
        )}
      </Stack>
      <BottomScreenContainer>
        {didSelectCallback ? (
          <NextButton
            onClick={() =>
              funnelApi.next({ selectedProduct, address: shippingAddress.current, didSelectCallback, confirmation })
            }
            label={t('callMeBack')}
            disabled={!confirmation}
          />
        ) : (
          <NextButton
            onClick={() => {
              const addressIsValid = addressFormRef?.current?.validate();
              if (addressIsValid) {
                funnelApi.next({
                  selectedProduct,
                  address: shippingAddress.current,
                  didSelectCallback,
                  confirmation,
                });
              }
            }}
            label={t('Checkout')}
            disabled={!confirmation}
          />
        )}

        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};

const RequestCallbackCard = ({ selected, onClick }: { selected: boolean; onClick: () => void }) => {
  const { t } = useTranslation();

  const stackRef = useRef(null);
  const [imageHeight, setImageHeight] = useState('auto');

  useEffect(() => {
    const stackHeight = stackRef?.current.clientHeight;
    setImageHeight(stackHeight);
  }, []);

  return (
    <UnstyledButton onClick={onClick}>
      <Card>
        <Group align="flex-start" wrap="nowrap">
          <Card
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            data-background={true}
            px={12}
            w={56}
            h={imageHeight}
          >
            <Image src={EmblaCoach} data-img-filter={true} />
          </Card>
          <Stack ref={stackRef} style={{ flex: 1 }} justify="flex-start" gap={0}>
            <Group justify="space-between">
              <Text fw={400} lh={'32px'} size={'24px'}>
                {t('requestACallback')}
              </Text>
              <Checkbox checked={selected} />
            </Group>
            <Text fw={400}>
              <Trans
                i18nKey={'gotQuestionsWellCallYouToProvideAnswers'}
                components={{
                  highlight: <span style={{ fontWeight: 600 }} />,
                }}
              />
            </Text>
          </Stack>
        </Group>
      </Card>
    </UnstyledButton>
  );
};
