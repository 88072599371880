import { Title, TitleProps } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { FunctionComponent, ReactNode } from 'react';

interface IProps extends Omit<TitleProps, 'title' | 'size'> {
  title: ReactNode;
}

export const StepTitle: FunctionComponent<IProps> = ({ title, ...titleProps }) => {
  const { width } = useViewportSize();
  const isMobile = width < 500;
  return (
    <Title ta="center" size={getSize(isMobile)} lh={getLh(isMobile)} fw={500} {...titleProps}>
      {title}
    </Title>
  );
};

const getSize = (isMobile: boolean): number => {
  return isMobile ? 24 : 36;
};

const getLh = (isMobile: boolean): number => {
  return isMobile ? 1.2 : 1.1;
};
