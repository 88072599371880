import { ReactComponent as IconArrowLeft } from '@assets/icons/fi-rr-arrow-left.svg';
import { ReactComponent as IconArrowRight } from '@assets/icons/fi-rr-arrow-right.svg';
import {
  ActionIcon,
  Group,
  Indicator,
  Paper,
  PaperProps,
  ScrollArea,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { useIntersection, useViewportSize } from '@mantine/hooks';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import weekday from 'dayjs/plugin/weekday';
import { FunctionComponent, forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

dayjs.extend(minMax);
dayjs.extend(weekday);

export type DateWithAvailability = {
  date: Date;
  numberOfAvailableTimelots: number;
};
interface IProps {
  data: DateWithAvailability[];
  setDate: (date: Date) => void;
  selected?: Date;
}

export const DateSelector: FunctionComponent<IProps> = ({ data, selected, setDate }) => {
  const { t } = useTranslation();
  const [leftMostVisibleDate, setLeftMostVisibleDate] = useState<Date>(new Date());
  const refs = useRef<
    ({ ref: HTMLButtonElement } & {
      dateWithAvailability: DateWithAvailability;
    })[]
  >([]);
  const listRef = useRef<HTMLDivElement>();
  const [cursor, setCursor] = useState(0);
  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
  const { ref: firstElementRef, entry: firstElementEntry } = useIntersection({
    root: listRef.current,
    threshold: 1,
    rootMargin: '48px',
  });
  const { ref: lastElementRef, entry: lastElementEntry } = useIntersection({
    root: listRef.current,
    threshold: 1,
  });
  const { width } = useViewportSize();
  const itemWidth = 56;
  const itemSpacing = 8;
  const fullItemWidth = (itemWidth + itemSpacing) * 3;

  const calculateScrollPosition = (index: number) => {
    return (index > 0 ? 36 : 0) + index * fullItemWidth;
  };

  const onNextItem = () => {
    listRef.current.scrollTo({
      left: calculateScrollPosition(cursor + 1),
      behavior: 'smooth',
    });
  };
  const onPrevItem = () => {
    listRef.current.scrollTo({
      left: calculateScrollPosition(cursor - 1),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setCursor(Math.round(scrollPosition.x / fullItemWidth));
    const foundRef = refs.current?.find((r) => {
      return r.ref.getClientRects()?.[0]?.x > width / 3;
    });
    if (foundRef) {
      setLeftMostVisibleDate(foundRef.dateWithAvailability?.date);
    }
  }, [scrollPosition.x, fullItemWidth, width]);
  return (
    <Stack gap={'sm'}>
      <Group justify={'space-between'}>
        <Text fw={600} size={'lg'}>
          {t('selectDate')}
        </Text>
        <Text data-secondary fw={600} size={'md'}>
          {leftMostVisibleDate ? dayjs(leftMostVisibleDate).format('MMMM') : null}
        </Text>
      </Group>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto',
          position: 'relative',
        }}
      >
        {!firstElementEntry?.isIntersecting ? (
          <Paper
            style={{
              position: 'absolute',
              top: 26,
              left: 12,
              zIndex: 100,
              alignSelf: 'center',
            }}
            mr={'xs'}
          >
            <ActionIcon variant="outline" size="lg" onClick={onPrevItem}>
              <IconArrowLeft width={12} />
            </ActionIcon>
          </Paper>
        ) : null}
        <div ref={firstElementRef} style={{ width: 0 }} />
        <ScrollArea viewportRef={listRef} onScrollPositionChange={onScrollPositionChange} scrollbarSize={0}>
          <Group w={'100%'} align="flex-start" wrap={'nowrap'} gap="sm">
            <div ref={firstElementRef} style={{ width: 0 }} />
            {data.map((d, i) => {
              const isSelected = selected?.getTime() === d.date.getTime();
              return (
                <DateCard
                  ref={(ref) => {
                    refs.current[i] = { ref, dateWithAvailability: d };
                    return ref;
                  }}
                  isSelected={isSelected}
                  shadow={isSelected ? 'sm' : 'xs'}
                  onClick={() => {
                    setDate(d.date);
                  }}
                  key={d.date.toISOString()}
                  data={d}
                />
              );
            })}
            <div ref={lastElementRef} style={{ minWidth: 0 }} />
          </Group>
        </ScrollArea>
        {!lastElementEntry?.isIntersecting ? (
          <Paper
            style={{
              position: 'absolute',
              top: 26,
              right: 12,

              alignSelf: 'center',
            }}
            ml={'xs'}
          >
            <ActionIcon variant="outline" size="lg" onClick={onNextItem}>
              <IconArrowRight width={12} />
            </ActionIcon>
          </Paper>
        ) : null}
      </div>
    </Stack>
  );
};

const DateCard = forwardRef<
  HTMLButtonElement,
  {
    isSelected?: boolean;
    data: DateWithAvailability;
    onClick: () => void;
  } & PaperProps
>(({ data, onClick, isSelected, ...props }, ref) => {
  const { date, numberOfAvailableTimelots } = data;

  const d = dayjs(date);

  const indicatorColor =
    numberOfAvailableTimelots >= 4 ? 'embla.3' : numberOfAvailableTimelots > 0 ? 'warning.3' : 'error.3';
  const isDisabled = !numberOfAvailableTimelots;
  return (
    <UnstyledButton
      my={'xs'}
      onClick={onClick}
      ref={ref}
      styles={{
        root: {
          borderRadius: '16px',
        },
      }}
      disabled={isDisabled}
    >
      <Paper w={56} p={12} {...props} data-selected={isSelected} data-disabled={isDisabled}>
        <Stack justify="center" align="center" gap={0}>
          <Text lh={'24px'} size={'md'} data-selectable-primary>
            {d.format('ddd')}
          </Text>
          <Text lh={'28px'} size={'lg'} data-selectable-secondary fw={600}>
            {d.format('D')}
          </Text>
          {!isDisabled ? <Indicator color={indicatorColor} size={4} /> : null}
        </Stack>
      </Paper>
    </UnstyledButton>
  );
});
