import { Stack, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

export const BirthDate: FunnelPageComponent<
  {
    value: Date;
  },
  IFunnelContext
> = ({ data: { value }, funnelApi }) => {
  const { t } = useTranslation();

  const form = useForm({
    initialValues: { birthDate: value ? new Date(value) : new Date() },
  });

  return (
    <form
      onSubmit={form.onSubmit(() => {
        funnelApi.next({
          value: form.values.birthDate,
        });
      })}
      style={{ flex: 1 }}
    >
      <FullScreenContainer>
        <Stack justify="center" align="center" gap={'xxl'}>
          <StepTitle title={t('whatsYourDateOfBirth')} />
          <Text size={'lg'} ta={'center'} mb={'md'} data-secondary-font>
            {t('weDontOfferTreatmentToPeopleUnderTheAgeOf18OrAbove75YearsOld')}
          </Text>
        </Stack>

        <Stack gap={'xl'}>
          <DatePickerInput
            label="Pick date"
            placeholder="Pick date"
            value={form.values.birthDate}
            onChange={(value) => form.setValues({ birthDate: value })}
          />
        </Stack>
        <BottomScreenContainer>
          <NextButton type="submit" disabled={!form.values.birthDate} />
          <BackButton onClick={() => funnelApi.back()} />
        </BottomScreenContainer>
      </FullScreenContainer>
    </form>
  );
};
