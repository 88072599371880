import { Stack, StackProps } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import React, { FunctionComponent } from 'react';

export const FullScreenContainer: FunctionComponent<StackProps> = ({ children }) => {
  const { width } = useViewportSize();
  const isMobile = width < 500;

  return (
    <Stack h={isMobile ? '100%' : undefined} gap={isMobile ? 24 : 48}>
      {children}
    </Stack>
  );
};
