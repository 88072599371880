import { Stack } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { TrustBox } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { Trans, useTranslation } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/v3/FunnelContext';

import { Benefits } from './Benefits';
import { YourRecommendedMembership } from './YourRecommendedMembership';
import { SwitchProgram } from '../Program/SwitchProgram';

export const Checkout: FunnelPageComponent<
  {
    selectedProduct: ProductSelection;
    productChanged?: boolean;
  },
  IFunnelContext
> = ({ context: { selectedProduct, selectedProductPriceInfo: price, eligibleFor }, funnelApi }) => {
  const { t } = useTranslation();

  return (
    <FullScreenContainer>
      <StepTitle title={<Trans i18nKey={'yourFutureSelfWillThankYou'} />} />
      <Stack gap="lg">
        <YourRecommendedMembership
          price={price}
          selectedProduct={selectedProduct}
          selected={true}
          onClick={() => {}}
          compact
        />

        {eligibleFor === ProductSelection.MedicationAndCoaching && (
          <SwitchProgram
            productChanged={true}
            selectedProduct={selectedProduct}
            onClick={() => {
              funnelApi.next({
                selectedProduct:
                  selectedProduct == ProductSelection.CoachingOnly
                    ? ProductSelection.MedicationAndCoaching
                    : ProductSelection.CoachingOnly,
                productChanged: true,
              });
            }}
          />
        )}

        <TrustBox />
        <Benefits selectedProduct={selectedProduct} />
      </Stack>
      <BottomScreenContainer>
        <NextButton onClick={() => funnelApi.next({ selectedProduct: selectedProduct })} label={t('Checkout')} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
