import { Group, InputLabel, Paper, Radio, Stack, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { RadioCard } from '@shared/components/RadioCard';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { UserSubmitFeedbackMutationVariables } from '@shared/gql/sdk';
import { showErrorNotification } from '@shared/showNotification';
import { useTheme } from '@shared/theme';
import { GetInputProps } from 'node_modules/@mantine/form/lib/types';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

const signupRelationOptions = ['member', 'partner', 'other'] as const;
type SignupRelation = (typeof signupRelationOptions)[number];

const signupRecommentOptions = ['yes', 'no', 'maybe'] as const;
type SignupRecommend = (typeof signupRecommentOptions)[number];

const signupExperienceOptions = [1, 2, 3, 4, 5] as const;
type SignupExperience = (typeof signupExperienceOptions)[number];

type FeedbackForm = {
  SIGNUP_EXPERIENCE: SignupExperience;
  SIGNUP_RECOMMEND: SignupRecommend;
  SIGNUP_RELATION: SignupRelation;
  SIGNUP_GENERAL: string;
};

export const Feedback: FunnelPageComponent<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  IFunnelContext,
  undefined,
  {
    submitFeedback: (data: UserSubmitFeedbackMutationVariables[]) => Promise<void>;
  }
> = ({ funnelApi, context }) => {
  const { t } = useTranslation();

  const form = useForm<FeedbackForm>();

  const renderSignupRelationCard = (value: SignupRelation, label: string) => {
    return (
      <RadioCard
        checked={form.getInputProps('SIGNUP_RELATION').value === value}
        value={value}
        label={label}
        select={() => {
          form.getInputProps('SIGNUP_RELATION').onChange(value);
        }}
      />
    );
  };

  const renderSignupRecommendCard = (value: SignupRecommend, label: string) => {
    return (
      <RadioCard
        checked={form.getInputProps('SIGNUP_RECOMMEND').value === value}
        value={value}
        label={label}
        select={() => {
          form.getInputProps('SIGNUP_RECOMMEND').onChange(value);
        }}
      />
    );
  };
  return (
    <form
      onSubmit={form.onSubmit(async (vals) => {
        try {
          await funnelApi.actions.submitFeedback(
            [
              Number(vals.SIGNUP_EXPERIENCE)
                ? {
                    configKey: 'SIGNUP_EXPERIENCE',
                    data: {
                      rating: Number(vals.SIGNUP_EXPERIENCE),
                      context: { source: context.source },
                    },
                  }
                : null,
              vals.SIGNUP_RECOMMEND
                ? {
                    configKey: 'SIGNUP_RECOMMEND',
                    data: {
                      selection: [vals.SIGNUP_RECOMMEND],
                      context: { source: context.source },
                    },
                  }
                : null,
              vals.SIGNUP_RELATION
                ? {
                    configKey: 'SIGNUP_RELATION',
                    data: {
                      selection: [vals.SIGNUP_RELATION],
                      context: { source: context.source },
                    },
                  }
                : null,
              vals.SIGNUP_GENERAL
                ? {
                    configKey: 'SIGNUP_GENERAL',
                    data: {
                      comment: vals.SIGNUP_GENERAL,
                      context: { source: context.source },
                    },
                  }
                : null,
            ].filter(Boolean),
          );

          funnelApi.next({});
        } catch (err) {
          showErrorNotification((err as Error)?.message);
        }
      })}
      style={{ flex: 1 }}
    >
      <FullScreenContainer>
        <Stack gap={'lg'}>
          <Stack gap={0}>
            <InputLabel>{t('howWasYouOverallExperience')}</InputLabel>
            <Rating {...form.getInputProps('SIGNUP_EXPERIENCE')} />
          </Stack>

          <Radio.Group {...form.getInputProps('SIGNUP_RECOMMEND')} label={t('wouldYouRecommendEmbla')}>
            <Stack gap="sm">
              {renderSignupRecommendCard('yes', t('yes'))}
              {renderSignupRecommendCard('no', t('no'))}
              {renderSignupRecommendCard('maybe', t('maybe'))}
            </Stack>
          </Radio.Group>

          <Radio.Group {...form.getInputProps('SIGNUP_RELATION')} label={t('whatIsYourRelationship')}>
            <Stack gap="sm">
              {renderSignupRelationCard('member', t('member'))}
              {renderSignupRelationCard('partner', t('partner'))}
              {renderSignupRelationCard('other', t('other'))}
            </Stack>
          </Radio.Group>

          <Textarea {...form.getInputProps('SIGNUP_GENERAL')} label={t('general')} autosize minRows={4} />
        </Stack>
        <BottomScreenContainer>
          <NextButton type="submit" />
        </BottomScreenContainer>
      </FullScreenContainer>
    </form>
  );
};

const Rating: FunctionComponent<ReturnType<GetInputProps<SignupExperience>>> = (props) => {
  const { theme: th } = useTheme();
  const theme = th.other.theme;

  return (
    <Group gap={'md'} flex={1}>
      {signupExperienceOptions.map((r) => {
        const value = Number(props.value) ?? 0;
        const isSelected = value >= r;

        return (
          <Paper
            key={r}
            radius={24}
            shadow={isSelected ? 'sm' : 'xs'}
            style={{
              display: 'flex',
              fontWeight: 'bold',
              height: 48,
              width: 48,
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: theme.selectable[isSelected ? 'selected' : 'default'].border,
              color: theme.selectable[isSelected ? 'selected' : 'default'].primary,
              backgroundColor: theme.selectable[isSelected ? 'selected' : 'default'].background,
              cursor: 'pointer',
            }}
            onClick={() => {
              props.onChange(r);
            }}
          >
            {r}
          </Paper>
        );
      })}
    </Group>
  );
};
