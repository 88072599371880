import { Stack, Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { DownloadButton } from '@shared/components/buttons/DownloadButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { PhoneImage } from '@shared/components/PhoneImage';
import { FunnelPageComponent } from '@shared/funnel-engine';

import { t } from '../../i18n';
import IFunnelContext from '../FunnelContext';

export const NotEligible: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ context, funnelApi }) => {
  const { embedded, locale, notEligibleReason, measurements } = context;
  const { bmi } = measurements;
  const { title, mdText1, mdText2 } = useNotEligibleContent(notEligibleReason, bmi);
  const showDownloadButton = notEligibleReason === 'low-bmi' || notEligibleReason === 'addiction';

  return (
    <FullScreenContainer gap="lg" py="md">
      <Text ta="center" size={'xl'} fw={500}>
        {title}
      </Text>
      <Stack gap="lg">
        {mdText1 && (
          <Text ta="center" data-secondary data-secondary-font>
            {mdText1}
          </Text>
        )}
        {mdText2 && (
          <Text ta="center" data-secondary data-secondary-font>
            {mdText2}
          </Text>
        )}
      </Stack>
      <BottomScreenContainer>
        {showDownloadButton && !embedded ? (
          <>
            <Text ta="center" data-primary size="lg" fw={600}>
              {t('youAreWelcomeToDownload')}
            </Text>
            <DownloadButton />
            <BackButton onClick={() => funnelApi.back()} />
            <PhoneImage locale={locale} type={'healthlog'} />
          </>
        ) : (
          <>
            {showDownloadButton && embedded && (
              <Text ta="center" data-primary size="lg" fw={600}>
                {t('youAreWelcomeToUse')}
              </Text>
            )}
            <BackButton onClick={() => funnelApi.back()} />
          </>
        )}
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};

const useNotEligibleContent = (reason: IFunnelContext['notEligibleReason'], bmi: number) => {
  const title = t('thanksForYourAnswers');
  let mdText1;
  let mdText2;

  switch (reason) {
    case 'low-bmi':
    case 'very-low-bmi':
      mdText1 = t('notEligibleBmi1', { bmi });
      mdText2 = t('notEligibleBmi2');
      break;
    case 'eating-disorder':
      mdText1 = t('notEligibleEatingDisorder');
      break;
    case 'addiction':
      mdText1 = t('notEligibleDrugsAlcohol');
      break;
  }

  return {
    title,
    mdText1,
    mdText2,
  };
};
