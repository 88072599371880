import { Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { NextButton } from '@shared/components/buttons/NextButton';
import { EmblaNumberInput } from '@shared/components/EmblaNumberInput';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { LeftLabel } from '@shared/components/LeftLabel';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useTranslation } from 'react-i18next';
import { t } from 'src/i18n';
import IFunnelContext from 'src/v3/FunnelContext';

type MeasurementData = {
  weightKg?: number;
  heightCm?: number;
  bloodPressureSystolic?: number;
  bloodPressureDiastolic?: number;
  cholesterolHdl?: number;
  cholesterolLdl?: number;
  cholesterolTotal?: number;
  hba1c?: number;
};

const numberInRange = (value: number, range: [number?, number?], msg?: string) => {
  if (value === undefined || value === null || typeof value !== 'number') return;

  if ((range[0] != null && value < range[0]) || (range[1] != null && value > range[1])) {
    if (msg) return msg;
    let message = t('notInRange');
    if (range[0] != null && range[1] != null) {
      message = t('valueMustBeBetween', { min: range[0], max: range[1] });
    } else if (range[0] == null) {
      message = t('valueMustBeLessThan', { max: range[1] });
    } else if (range[1] == null) {
      message = t('valueMustBeGreaterThan', { min: range[0] });
    }
    return message;
  }
};
const inRange = (range: [min: number, max: number]) => (value: number) =>
  numberInRange(value, range, t('valueMustBeBetween', { min: range[0], max: range[1] }));

export const createEnterMeasurementsPage =
  (includeHeight = false): FunnelPageComponent<MeasurementData, IFunnelContext> =>
  ({ funnelApi, data }) => {
    const { t } = useTranslation();
    const form = useForm<MeasurementData>({
      initialValues: data,
      validateInputOnBlur: true,
      validate: {
        weightKg: inRange([50, 250]),
        heightCm: inRange([100, 300]),
        hba1c: inRange([0, 100]),
        bloodPressureSystolic: inRange([20, 200]),
        bloodPressureDiastolic: inRange([20, 160]),
        cholesterolTotal: inRange([0, 500]),
        cholesterolLdl: inRange([0, 300]),
        cholesterolHdl: inRange([0, 100]),
      },
    });

    return (
      <FullScreenContainer>
        <StepTitle title={t('enterMeasurements.title')} />
        {includeHeight && (
          <LeftLabel label={t('height')}>
            <EmblaNumberInput
              {...form.getInputProps('heightCm')}
              mode="measurement"
              width="10rem"
              suffix="cm"
              step={0.1}
              decimalScale={1}
              decimalSeparator="."
            />
          </LeftLabel>
        )}
        <LeftLabel label={t('weight')}>
          <EmblaNumberInput
            {...form.getInputProps('weightKg')}
            mode="measurement"
            width="10rem"
            suffix="kg"
            step={0.1}
            decimalScale={1}
            decimalSeparator="."
          />
        </LeftLabel>
        <LeftLabel label={t('hba1c')}>
          <EmblaNumberInput
            {...form.getInputProps('hba1c')}
            mode="measurement"
            width="10rem"
            suffix="mmol/mol"
            step={0.1}
            decimalScale={1}
            suffixWidth={80}
            decimalSeparator="."
          />
        </LeftLabel>
        <LeftLabel label={t('bloodPressure')}>
          <Group gap={5}>
            <EmblaNumberInput
              {...form.getInputProps('bloodPressureSystolic')}
              mode="measurement"
              placeholder="S"
              width="10rem"
              suffix="mmHg"
              suffixWidth={60}
              step={1}
            />
            <span style={{ width: 5 }}>/</span>
            <EmblaNumberInput
              {...form.getInputProps('bloodPressureDiastolic')}
              mode="measurement"
              placeholder="D"
              width="10rem"
              suffix="mmHg"
              suffixWidth={60}
              step={1}
            />
          </Group>
        </LeftLabel>
        <LeftLabel label={t('cholesterol')}>
          <Group gap={5}>
            <EmblaNumberInput
              {...form.getInputProps('cholesterolTotal')}
              mode="measurement"
              placeholder={t('cholesterol.total')}
              width="10rem"
              suffix="mg/Dl"
              suffixWidth={60}
              step={1}
            />
            <span style={{ width: 5 }} />
            <EmblaNumberInput
              {...form.getInputProps('cholesterolHdl')}
              mode="measurement"
              placeholder={t('cholesterol.hdl')}
              width="10rem"
              suffix="mg/Dl"
              suffixWidth={60}
              step={1}
            />
            <span style={{ width: 5 }} />
            <EmblaNumberInput
              {...form.getInputProps('cholesterolLdl')}
              mode="measurement"
              placeholder={t('cholesterol.ldl')}
              width="10rem"
              suffix="mg/Dl"
              suffixWidth={60}
              step={1}
            />
          </Group>
        </LeftLabel>
        <BottomScreenContainer>
          <NextButton disabled={!form.isValid()} onClick={() => funnelApi.next(form.values)} />
        </BottomScreenContainer>
      </FullScreenContainer>
    );
  };
