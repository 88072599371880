import projectionGraphCoachingOnly from '@assets/weight-loss-prediction-coaching-only.svg';
import { Group, Image, Stack, Text } from '@mantine/core';
import { IndicatorWithLabel } from '@shared/components/IndicatorWithLabel';
import { StepTitle } from '@shared/components/StepTitle';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export const Coaching: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Stack align="center" gap="lg">
      <StepTitle title={t('loseWeightWithoutStarvingYourself')} />
      <Stack maw={480} w={'100%'} gap={0}>
        <WeightProjectionGraphCoachingOnly />
      </Stack>
    </Stack>
  );
};

const WeightProjectionGraphCoachingOnly: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Stack>
      <Group style={{ justifyContent: 'center' }}>
        <Text fw={'bold'} size="xl">
          {t('yourWeightLossJourney')}
        </Text>
      </Group>
      <Stack gap="xs" style={{ position: 'relative' }}>
        <Group w="100%" wrap="nowrap" align="stretch" gap={'sm'}>
          <div
            style={{
              display: 'flex',
              paddingTop: 10,
              paddingBottom: 10,
              position: 'relative',
              flex: 1,
            }}
          >
            <Image width="100%" style={{ flex: 1 }} src={projectionGraphCoachingOnly} />
          </div>
        </Group>
        <Group justify="center" style={{ position: 'absolute', bottom: 60, width: '100%' }}>
          <IndicatorWithLabel label={t('restrictiveDiet')} indicatorColor={'#808080'} />
          <IndicatorWithLabel label={t('emblaCoaching')} indicatorColor={'#000000'} />
        </Group>
      </Stack>
    </Stack>
  );
};
