import avatar from '@assets/avatar_180px.svg';
import { Group, Image, List, Stack, Text, TextProps, Title, TitleProps } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { TrustBox } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { ReactNode } from 'react';
import IFunnelContext from 'src/v3/FunnelContext';
type UtilityPageBaseOptions = { content?: ReactNode; nextLabel?: string; showTrustpilot?: boolean };
type UtilityPageOptions<T> = UtilityPageBaseOptions & T;

type TitleTextSection = {
  title: ReactNode;
  text?: ReactNode;
  titleProps?: TitleProps;
  textProps?: TextProps;
  image?: boolean;
};

const createTitleTextSectionsPage =
  (
    options: UtilityPageOptions<{
      sections: TitleTextSection[];
    }>,
  ): FunnelPageComponent<undefined, IFunnelContext> =>
  ({ funnelApi }) => {
    return (
      <FullScreenContainer>
        <Stack my="xl">
          {options.sections.map(({ title, titleProps, text, textProps, image }) => (
            <Stack gap={'md'}>
              {image ? (
                <Group justify="center" align="center">
                  <Image src={avatar} w={80} h={80} />
                </Group>
              ) : null}
              <Title ta={'center'} size={'h3'} lh={1.1} {...titleProps}>
                {title}
              </Title>
              <Text data-secondary-font size={'xl'} ta={'center'} {...textProps}>
                {text}
              </Text>
            </Stack>
          ))}
        </Stack>
        {options.content}
        {options.showTrustpilot && <TrustBox />}
        <BottomScreenContainer>
          <NextButton grow label={options.nextLabel} onClick={funnelApi.next} />
          {funnelApi.state.hasPreviousPage && <BackButton grow onClick={funnelApi.back} />}
        </BottomScreenContainer>
      </FullScreenContainer>
    );
  };

const createTitleTextPage =
  (options: UtilityPageOptions<TitleTextSection>): FunnelPageComponent<undefined, IFunnelContext> =>
  ({ funnelApi }) => {
    return (
      <Stack>
        <Title ta={'center'} size={'h3'} {...options.titleProps}>
          {options.title}
        </Title>
        <Text {...options.textProps} data-secondary-font size={'xl'} ta={'center'}>
          {options.text}
        </Text>
        {options.content}
        {options.showTrustpilot && <TrustBox />}
        <NextButton grow label={options.nextLabel} onClick={funnelApi.next} />
        {funnelApi.state.hasPreviousPage && <BackButton grow onClick={funnelApi.back} />}
      </Stack>
    );
  };

const createTitleBulletPointsPage =
  (
    options: UtilityPageOptions<{ title: ReactNode; titleProps?: TitleProps; bulletpoints: ReactNode[] }>,
  ): FunnelPageComponent<undefined, IFunnelContext> =>
  ({ funnelApi }) => {
    return (
      <Stack>
        <Title ta={'center'} size={'h3'} {...options.titleProps}>
          {options.title}
        </Title>
        <List withPadding style={{ listStylePosition: 'outside' }} spacing={8}>
          {options.bulletpoints.map((b) => (
            <List.Item key={String(b)}>
              <Text data-secondary-font size={'xl'} ta={'center'}>
                {b}
              </Text>
            </List.Item>
          ))}
        </List>
        {options.content}
        {options.showTrustpilot && <TrustBox />}
        <NextButton grow label={options.nextLabel} onClick={funnelApi.next} />
        {funnelApi.state.hasPreviousPage && <BackButton grow onClick={funnelApi.back} />}
      </Stack>
    );
  };

export const PageTemplates = {
  TitleText: createTitleTextPage,
  TitleBulletPoints: createTitleBulletPointsPage,
  TitleTextSections: createTitleTextSectionsPage,
};
