import { Image, Title } from '@mantine/core';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/v3/FunnelContext';

export const MeasurementIntro: FunnelPageComponent<undefined, IFunnelContext> = ({ context, funnelApi }) => {
  const { t } = useTranslation();
  const {
    partnerClientSession: {
      clientInfo: { firstName },
    },
    partnerInfo,
  } = context;

  return (
    <FullScreenContainer>
      <Image src={partnerInfo.logoImageUrl} w={250} />
      <Title mb="sm" lh={1.1}>
        {t('measurementIntro', { name: firstName })}
      </Title>
      <BottomScreenContainer>
        <NextButton label={t('ok')} grow onClick={funnelApi.next} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
