import api, { isAuthenticated } from '@shared/api';
import { FunnelEngineData } from '@shared/funnel-engine';
import { env } from 'src/env';
import IFunnelContext from 'src/v3/FunnelContext';

import { createPartnerFunnelConfig } from '../config';

type Data = FunnelEngineData<ReturnType<typeof createPartnerFunnelConfig>['config']>;

export const saveData = async (data: Data, context: IFunnelContext, terminal = false) => {
  if (!isAuthenticated()) return;

  try {
    const { registration } = data;
    await api.SignupFunnelFinalizeV3({
      data: {
        partnerClientSessionId: context.partnerClientSession?.id,
        glp1: registration.glp1.value,
        comorbidity: registration.comorbidity,
        eligibility: registration.eligibility,
        height_weight: registration.height_weight,
        emblaShareCode: env.partnerCoupon,
        notEligibleReason: context.notEligibleReason,
      },
      terminal,
    });
  } catch (e) {
    console.warn('Failed to save data');
  }
};
