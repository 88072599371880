import { Radio, Stack } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { RadioCard } from '@shared/components/RadioCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { FeelingAboutWeightLoss } from '@shared/gql/sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { t } from '../../i18n';
import IFunnelContext from '../FunnelContext';

const getFeelingTranslations = (): Record<FeelingAboutWeightLoss, string> => {
  return {
    WORRIED: t('worriedLoosingMotivation'),
    FRUSTRATED: t('frustratedFindItDifficult'),
    HOPEFUL: t('hopefulCouldUseSomeGuidance'),
    OPTIMISTIC: t('optimisticOnTheRightTract'),
    CONFIDENT: t('confidentICanDoIt'),
    NOT_SURE: t('ImNotSureButInterested'),
  };
};

export const HealthFeelings: FunnelPageComponent<
  { feelingAboutWeightLoss?: FeelingAboutWeightLoss },
  IFunnelContext
> = ({ data: { feelingAboutWeightLoss }, funnelApi }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<FeelingAboutWeightLoss>(feelingAboutWeightLoss);
  const translations = getFeelingTranslations();
  return (
    <FullScreenContainer>
      <StepTitle title={t('howDoYouFeelAboutTryingToLooseWeight')} />
      <Radio.Group value={value}>
        <Stack gap="sm">
          {Object.keys(translations).map((key) => {
            const feeling = key as FeelingAboutWeightLoss;
            return (
              <RadioCard
                key={key}
                label={translations[feeling]}
                checked={value === feeling}
                value={feeling}
                select={() => {
                  setValue(feeling);
                }}
              />
            );
          })}
        </Stack>
      </Radio.Group>
      <BottomScreenContainer>
        <NextButton
          type="submit"
          disabled={!value}
          onClick={() =>
            funnelApi.next({
              feelingAboutWeightLoss: value,
            })
          }
        />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
