import { Title } from '@mantine/core';
import { GrowingButton } from '@shared/components/buttons/GrowingButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { getRootPath } from '@shared/helpers';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

export const FeedbackThankYou: FunnelPageComponent<undefined, IFunnelContext, undefined> = ({
  context: { embedded },
}) => {
  const { t } = useTranslation();
  return (
    <FullScreenContainer justify="center">
      <Title ta={'center'}>{t('thankYouForYourFeedback')}</Title>
      <BottomScreenContainer>
        {embedded ? (
          <GrowingButton
            data-testid="next"
            type="submit"
            onClick={() => {
              window.location.href = `${getRootPath()}/app-complete`;
            }}
            buttonLabel={t('finish')}
          ></GrowingButton>
        ) : null}
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
