import { Stack, StackProps } from '@mantine/core';
import React, { FunctionComponent } from 'react';

export const BottomScreenContainer: FunctionComponent<StackProps> = ({ children, ...props }) => {
  return (
    <Stack {...props} style={{ marginTop: 'auto' }} pb={'md'}>
      {children}
    </Stack>
  );
};
