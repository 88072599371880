import { ReactComponent as IconCheck } from '@assets/icons/fi-rr-check.svg';
import { ReactComponent as IconCheckbox } from '@assets/icons/icon-checkbox.svg';
import AppTrioImage from '@assets/images/phone-trio.png';
import { Image, Stack, Text } from '@mantine/core';
import { DownloadButton } from '@shared/components/buttons/DownloadButton';
import { FeedbackLink } from '@shared/components/buttons/FeedbackLink';
import { GrowingButton } from '@shared/components/buttons/GrowingButton';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { getRootPath } from '@shared/helpers';
import { useTranslation } from 'react-i18next';

import { FaqCard } from './components/FaqCard';
import IFunnelContext from '../FunnelContext';

export const CheckoutConfirmation: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ context }) => {
  const { t } = useTranslation();
  const isEmbeddedInApp = context.embedded;

  return (
    <FullScreenContainer gap="lg" py="md">
      <Stack align="center">
        <IconCheckbox height={40} />
        <StepTitle mt={0} mb={0} title={t('yourMembershipIsActivated')} />
        <Stack gap={0}>
          <Text ta="center" fw={400} size="lg">
            {t('firstNameWelcomeToEmbla', { firstName: context.userSession.firstName })}
          </Text>
          <Text ta="center" fw={400} size="lg">
            {t('weHaveSentAReceiptTo', { email: context.userSession.email })}
          </Text>
        </Stack>

        <Text ta="center" fw={400} size="lg">
          {t('toGetStartedDownloadTheEmblaApp')}
        </Text>
      </Stack>
      <Stack gap="sm">
        {!isEmbeddedInApp && <DownloadButton />}
        {isEmbeddedInApp && (
          <GrowingButton
            variant="transparent"
            buttonLabel={t('finish')}
            onClick={() => {
              // on this url the app knows that it's time to close webview and refetch user data
              window.location.href = `${getRootPath()}/app-complete`;
            }}
            leftSection={<IconCheck width={20} />}
          />
        )}
        <Image src={AppTrioImage} height={380} fit="contain" data-img-filter={true} />
      </Stack>
      <FaqCard />
      <FeedbackLink embedded={isEmbeddedInApp} source={context.source} />
    </FullScreenContainer>
  );
};
