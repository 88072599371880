import { Text } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { EaseInAndOut } from './EaseInAndOut';
import { TrustBox } from './TrustBox';
import { Quote } from '../types';

interface IQuoteComponentProps extends Quote {
  isMounted: boolean;
  totalTime: number;
}

export const QuoteComponent: FunctionComponent<IQuoteComponentProps> = ({
  quote,
  reference,
  trustPilot,
  isMounted,
  totalTime,
}) => {
  return (
    <EaseInAndOut isMounted={isMounted} enterDuration={600} exitDuration={200} totalTime={totalTime}>
      <Text ta="center" fw={600} size={'xl'} py={'md'} data-secondary-font data-secondary>
        "{quote}"
      </Text>
      {trustPilot && <TrustBox />}
      {reference && (
        <Text ta="center" fw={300} size={'sm'} fs="italic">
          {reference}
        </Text>
      )}
    </EaseInAndOut>
  );
};
