import AppIllustration from '@assets/images/app_illustration.svg';
import { Flex, Image, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

export const ProbablyEligible: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => {
  const { t } = useTranslation();
  const { width } = useViewportSize();

  return (
    <FullScreenContainer>
      <StepTitle title={t('itLooksLikeWeCanOfferYouAProgramAtEmbla')} />
      <Text ta="center" data-secondary>
        {t('probablyEligible.nextStepIsToBookAScreening')}
      </Text>
      <Flex justify="center" pt={width > 375 ? 70 : null}>
        <Image width={250} data-img-filter src={AppIllustration} />
      </Flex>
      <BottomScreenContainer>
        <NextButton onClick={() => funnelApi.next({})} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
