import { Group, Paper, Stack, Text, UnstyledButton } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { TimeOfDay } from '@shared/gql/sdk';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

dayjs.extend(localizedFormat);

export enum PickTimeslotErrors {
  AppointmentNotAvailable = 'appointment-not-available',
}

export const PickTimeOfDay: FunnelPageComponent<
  {
    selectedTimeOfDay?: TimeOfDay;
  },
  IFunnelContext,
  PickTimeslotErrors
> = ({ context: { timeOfDay }, funnelApi }) => {
  const { t } = useTranslation();
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState<TimeOfDay>(timeOfDay);

  const translations: Partial<Record<TimeOfDay, string>> = {
    MORNING: t('morning'),
    AFTERNOON: t('afternoon'),
    EVENING: t('evening'),
  };
  return (
    <FullScreenContainer>
      <StepTitle title={t('whenIsTheMostConvenientTimeForUsToCall')} />

      <Stack gap="xl">
        <Stack gap="sm" align="center">
          <Text fw={600}>{t('selectThePreferredTimeOfDay')}</Text>
          <Group gap="sm" justify="center">
            {[TimeOfDay.Morning, TimeOfDay.Afternoon, TimeOfDay.Evening].map((opt) => {
              const isSelected = opt === selectedTimeOfDay;
              return (
                <UnstyledButton
                  key={opt}
                  onClick={() => {
                    setSelectedTimeOfDay(opt);
                  }}
                  styles={{
                    root: {
                      borderRadius: '8px',
                    },
                  }}
                >
                  <Paper
                    px={'lg'}
                    py="sm"
                    radius={'md'}
                    role="button"
                    shadow={isSelected ? 'md' : 'sm'}
                    data-selected={isSelected}
                  >
                    <Group justify="center">
                      <Text data-selectable-secondary fw={600}>
                        {translations?.[opt]}
                      </Text>
                    </Group>
                  </Paper>
                </UnstyledButton>
              );
            })}
          </Group>
        </Stack>
        <Text fw={400} ta="center">
          {t('wellGetBackToYouPrompty')}
        </Text>
      </Stack>
      <BottomScreenContainer>
        <NextButton disabled={!selectedTimeOfDay} onClick={() => funnelApi.next({ selectedTimeOfDay })} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
