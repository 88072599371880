import { Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { CheckboxCard } from '@shared/components/CheckboxCard';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { CurrentMedicationAnswersInput } from '@shared/gql/sdk';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

type MedicationQuestion = keyof CurrentMedicationAnswersInput;
type MedicationForm = Record<MedicationQuestion, boolean>;

export const CurrentMedications: FunnelPageComponent<Partial<MedicationForm>, IFunnelContext> = ({
  data: questions,
  funnelApi,
}) => {
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      INSULIN: questions?.INSULIN || false,
      SULFONYLUREAS: questions?.SULFONYLUREAS || false,
      ORLISTAT: questions?.ORLISTAT || false,
      ANTIPSYCHOTIC: questions?.ANTIPSYCHOTIC || false,
      NONE: questions?.NONE || false,
    },
  });

  return (
    <FullScreenContainer>
      <StepTitle title={t('areYouCurrentlyTakingAnyOfTheFollowingMedications')} />
      <Stack gap="sm">
        <CheckboxCard
          label={t('medicationQuestion.insulin')}
          {...form.getInputProps('INSULIN', { type: 'checkbox' })}
          onChange={(val) => {
            form.getInputProps('INSULIN', { type: 'checkbox' }).onChange(val);
            if (val) {
              form.setFieldValue('NONE', false);
            }
          }}
        />
        <CheckboxCard
          label={t('medicationQuestion.sulfonylureas')}
          {...form.getInputProps('SULFONYLUREAS', { type: 'checkbox' })}
          onChange={(val) => {
            form.getInputProps('SULFONYLUREAS', { type: 'checkbox' }).onChange(val);
            if (val) {
              form.setFieldValue('NONE', false);
            }
          }}
        />
        <CheckboxCard
          label={t('medicationQuestion.orlistat')}
          {...form.getInputProps('ORLISTAT', { type: 'checkbox' })}
          onChange={(val) => {
            form.getInputProps('ORLISTAT', { type: 'checkbox' }).onChange(val);
            if (val) {
              form.setFieldValue('NONE', false);
            }
          }}
        />
        <CheckboxCard
          label={t('medicationQuestion.antipsychotics')}
          {...form.getInputProps('ANTIPSYCHOTIC', { type: 'checkbox' })}
          onChange={(val) => {
            form.getInputProps('ANTIPSYCHOTIC', { type: 'checkbox' }).onChange(val);
            if (val) {
              form.setFieldValue('NONE', false);
            }
          }}
        />
        <CheckboxCard
          label={t('noneOfTheAbove')}
          {...form.getInputProps('NONE', { type: 'checkbox' })}
          onChange={(value) => {
            form.getInputProps('NONE', { type: 'checkbox' }).onChange(value);
            if (value) {
              form.setValues({
                INSULIN: false,
                SULFONYLUREAS: false,
                ORLISTAT: false,
                ANTIPSYCHOTIC: false,
              });
            }
          }}
        />
      </Stack>
      <BottomScreenContainer>
        <NextButton
          disabled={!Object.values(form.values).filter(Boolean).length}
          onClick={() => funnelApi.next(form.values)}
        />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
