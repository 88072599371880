import { Affix, Group, Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EmblaLink } from './EmblaLink';

export const RecapthchaBanner: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();
  const { width } = useViewportSize();

  const banner = (
    <Group
      justify={'center'}
      align={'center'}
      style={{
        display: 'flex',
        borderRadius: 32,
        border: 'solid 2px white',
        margin: '0 auto',
      }}
      px={'md'}
      py={'md'}
      gap={'sm'}
    >
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.9947 4.9031C18.9786 4.73477 18.9199 4.57329 18.8242 4.43387C18.7285 4.29445 18.599 4.18165 18.4477 4.1061L10.4747 0.106098C10.336 0.0360393 10.1828 -0.000626822 10.0274 -0.000974073C9.87201 -0.00132132 9.71869 0.0346601 9.57967 0.104098L1.55267 4.1041C1.25567 4.2541 1.05067 4.5411 1.00867 4.8711C0.99567 4.9681 -0.13633 14.6121 9.54967 19.8791C9.69856 19.9603 9.86575 20.0021 10.0353 20.0005C10.2049 19.999 10.3713 19.9541 10.5187 19.8701C19.8257 14.6111 19.0327 5.2971 18.9947 4.9031ZM10.0177 17.8471C3.15767 13.8371 2.87767 7.4951 2.95467 5.6421L10.0257 2.1191L17.0237 5.6301C17.0287 7.5001 16.5427 13.8731 10.0177 17.8471Z"
          fill="#393939"
        />
      </svg>

      <Stack align="flex-start" justify="center" gap={0}>
        <Text size={'xxs'}>{t('thisSiteIsProtectedByRecaptchaEnterprise')}</Text>
        <Group flex={0} align="center" justify="center" gap={4}>
          <Text size={'xxs'}>{t('theGoogle')}</Text>
          <EmblaLink href="https://policies.google.com/privacy" size="xxs">
            {t('recaptchaPrivacyPolicy')}
          </EmblaLink>
          <Text size={'xxs'}>{t('and')}</Text>
          <EmblaLink href="https://policies.google.com/terms" size="xxs">
            {t('recaptchaTermsOfService')}
          </EmblaLink>
          <Text size={'xxs'}>{t('apply')}</Text>
        </Group>
      </Stack>
    </Group>
  );

  if (width > 1350) {
    return <Affix position={{ bottom: 20, left: 20 }}>{banner}</Affix>;
  }

  return banner;
};
